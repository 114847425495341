import React, {useState, useEffect} from 'react';
import { ContentTile } from "@vaccinessites/components";
import { Row, Col} from 'react-bootstrap';
import { CMSService } from "@vaccinessites/services";
import './ContentTileBanner.scss';
import {CTA} from '../CTA/CTA';

export function ContentTileBanner (props) {
    let contentTileBanner = props.contentTileBanner;
    let header = contentTileBanner.fields.heading;
    let subheading = contentTileBanner.fields.subheading;
    let paragraph = contentTileBanner.fields.paragraph;
    let bannerTileTemplate = contentTileBanner.fields.bannerTemplate;
    let tileTempalte = contentTileBanner.fields.tileTemplate;
    let tilePerRow = contentTileBanner.fields.tilesPerRow;
    let link = contentTileBanner.fields.cta;
    let image = contentTileBanner.fields.image ? contentTileBanner.fields.image : null;
    let backgroundImage = contentTileBanner.fields.backgroundImage  ? contentTileBanner.fields.backgroundImage.fields.file.url : null;
    let backgroundColor = contentTileBanner.fields.backgroundColor ? contentTileBanner.fields.backgroundColor : null;
    
    return (
        <div className= {"content-tile-banner " +bannerTileTemplate+ " "+ backgroundColor}
             style={{  
                backgroundImage: (backgroundImage ? "url(" + backgroundImage + ")" : ""),
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            >
            <div className="container"> 
                <div className="tile-container">
                    <h1 className = "heading">{header}</h1>
                    <h3 className = "subheading">{subheading}</h3>
                    <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(paragraph) }}></div>
                    <Row xs={1} md={tilePerRow > 1 && tileTempalte == 'text-only' || 'large-image' ? 2 : 1}  lg={tilePerRow > 2 ? (tileTempalte == 'text-only' || tileTempalte =='text-only-2' || tileTempalte =='large-image'||  tileTempalte =='icon' || tileTempalte ==='image-no-shadow' || tileTempalte ==='left-image'? 3 : 4) :2} xl={tilePerRow}>
                        {
                            contentTileBanner.fields.contentTile && <> 
                                {contentTileBanner.fields.contentTile.map((content, i)=>{  
                                    return (
                                        <Col key={"tile-"+i}> <ContentTile contentTile = {content} template = {tileTempalte} />   </Col>                              
                                    )
                                })}    
                                      
                            </> 
                        } 
                    </Row>
                    {link && <CTA url={link.fields.url} className={"banner-link " + link.fields.type} > {link.fields.label} </CTA>} 
                </div>
                {image && 
                <div className="img-container">
                    <img className="img-fluid" src={image.fields.file.url} alt={image.fields.title} />
                </div>
                } 
            </div>      
        </div>
    );
}