import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Banner } from '../Banner/Banner';
import { CMSService } from "@vaccinessites/services";
import './GrantPage.scss';

export function GrantPage(props){
    const [grant, setGrant]= useState('');
    const {slug} = useParams();
    let history = useHistory();
    useEffect(()=>{
        props.CMS.getGrant(slug).then(grantData=>{
            if(grantData){
                setGrant(grantData.fields)
                document.title = grantData.fields.grantTitle
            } else {
                history.push('/404')
            }
        })
    }, [slug])
    let noSideBar = !grant.sidebar && !grant.investigatorImage
    return (
        <div className="grant-page">
            <div className="row">
                <div className={"main-column col-12 " + (noSideBar ? " " : "col-md-7")}>
                        <h2>
                        {grant.investigatorImage && 
                        <img className="img-fluid" src={grant.investigatorImage.fields.file.url}  alt={grant.investigatorImage.fields.description} />}
                        {grant.grantTitle}
                        </h2>
                    <div dangerouslySetInnerHTML={{ __html: CMSService.getRichText(grant.mainColumn)}}></div>
                </div>
                {!noSideBar && <div className="col-12 col-md-5">
                    <div className="side-bar">
                        <div dangerouslySetInnerHTML={{ __html: CMSService.getRichText(grant.sidebar)}}></div>
                    </div>
                </div>}
            </div>
        </div>
    )
}