import React, { useEffect, useState } from 'react';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { CTA } from '../CTA/CTA';
import { CMSService } from "@vaccinessites/services";
import { ContentTile } from '../ContentTile/ContentTile';
import '../Banner/Banner.scss';
import './CarouselBanner.scss';


function generateSliderGroupings(carousel, slides){
    let allSlidesIdx= [...Array(carousel.details().size).keys()]
    let views=[];
    //split number of slides into groupings so we can find which slide is the current slide
    while(allSlidesIdx.length) {
        if(allSlidesIdx.length >= slides){
            views.unshift(allSlidesIdx.splice(allSlidesIdx.length-slides,slides));
        } else {
            views.unshift(allSlidesIdx.splice(0, slides))
        }
    }
    return views;
}

export function CarouselBanner (props){
    let carousel = props.carousel.fields;
    let backgroundColor = carousel.backgroundColor && carousel.backgroundColor.toLowerCase().replace(' ', '');
    let link = carousel.cta;
    const [sliderRef, slider] = useKeenSlider({slidesPerView: 3, slideChanged(s) {
        setCurrentSlide(s.details().relativeSlide);
      }, mounted(c){
        setNumSlidesPerView(c.details().slidesPerView)
      }, breakpoints: {
        '(max-width: 767px)': {
            slidesPerView: 1
        },
        '(min-width: 768px) and (max-width: 992px)': {
            slidesPerView: 2
        }
      }});
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [numSlidesPerView, setNumSlidesPerView] = useState(0);
    let sliderDots = slider && [...Array(Math.ceil(slider.details().size / numSlidesPerView)).keys()];
    let sliderGroupings = slider && generateSliderGroupings(slider, numSlidesPerView);
    let heading = carousel.heading;
    let paragraph = carousel.paragraph;

    return(
        <div className={"carousel-banner banner " + backgroundColor}>
            <div className="container">
                <div className="row">
                    {(heading || paragraph) && <div className="text-container col-12 col-lg-9">
                        <h1 className="heading"> {heading} </h1>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(paragraph) }}></div>
                    </div>}
                    <div className="btn-container col-12 col-lg-3">
                        {link ? <CTA url={link.fields.url} className="btn-default">{link.fields.label}</CTA>                        
                        : null }
                    </div>
                </div>
                <div ref={sliderRef} className="row carousel-row" className="keen-slider">
                    {carousel.contentTiles.map((tile, i)=>{
                        return (
                            tile.fields && <div key={'tile-'+i} className={"col-12 col-md-6 col-lg-4 carousel-tile keen-slider__slide number-slide"+i} 
                                onBlur={()=>{
                                    slider.moveToSlide(i)
                                }}>
                                <ContentTile contentTile={tile} template={carousel.contentTileTemplate}/>
                            </div>
                        )
                    })}
                </div>
                {slider && (
                    <div className="dots">
                    {
                    sliderDots.map((idx) => {
                        let slideNum = idx * numSlidesPerView;

                        let activeDotIdx = (sliderGroupings.findIndex(i=>{
                            return i.includes(currentSlide)
                        }))
                        
                        return (
                        <button
                            key={idx}
                            onClick={() => {
                                slider.moveToSlide(slideNum);
                            }}
                            aria-label={"navigate to slide "+ slideNum}
                            className={"dot" + ( idx=== activeDotIdx ? " active" : "")}
                        />
                        );
                    })}
                    </div>
                )}
            </div>
        </div>
    )
}