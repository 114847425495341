import React, { Component, useState } from 'react';
import './ImageBanner.scss';

export function ImageBanner (props) {
    let imageBanner = props.imageBanner.fields;
    let image = imageBanner.image;
    return (
            <div className={"image-banner "  + imageBanner.backgroundColor}>
                <div className="container">
                    {imageBanner.heading && <h1 className="heading"> {imageBanner.heading} </h1>}
                    {image && 
                            <div className="img-container">
                                <img className="img-fluid" src={image.fields.file.url} alt={image.fields.title} />
                    </div> }
                </div>
            </div>
    )
}