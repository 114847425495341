import React, {useState, useEffect, useMemo} from 'react';
import { ContentTile } from "@vaccinessites/components";
import { Row, Col} from 'react-bootstrap';
import { CMSService } from "@vaccinessites/services";
import './PublicationTileBanner.scss';
import {CTA} from '../CTA/CTA';
import { Pagination } from './Pagination';
import ScrollToTopButton from './ScrollToTop';

const isDataSelected = (tile, selectedItems, itemKey) => {
    
    return !selectedItems.length || selectedItems.includes(tile.fields[itemKey].replace(/[^a-zA-Z ]/g, ""));
};

const isSearchContains = (tile, searchResults) => {
    var isContains = false;
    searchResults.forEach(item => {
         if(item.sys.id === tile.sys.id){
            isContains = true;
            return;
         }
        });
    return isContains;
};


export function PublicationTileBanner (props) {
    let publicationTileBanner = props.publicationTileBanner;
    let header = publicationTileBanner.fields.heading;
    let subheading = publicationTileBanner.fields.subheading;
    let paragraph = publicationTileBanner.fields.paragraph;
    let bannerTileTemplate = publicationTileBanner.fields.bannerTemplate;
    let tileTempalte = publicationTileBanner.fields.tileTemplate;
    let tilePerRow = publicationTileBanner.fields.tilesPerRow;
    let link = publicationTileBanner.fields.cta;
    let image = publicationTileBanner.fields.image ? publicationTileBanner.fields.image : null;
    let backgroundImage = publicationTileBanner.fields.backgroundImage  ? publicationTileBanner.fields.backgroundImage.fields.file.url : null;
    let backgroundColor = publicationTileBanner.fields.backgroundColor ? publicationTileBanner.fields.backgroundColor : null;
    let contentTile = props.publicationTileBanner.fields.contentTile;
    let tileFilter = props.publicationTileBanner.fields.tileFilter;
    let PageSize = props.publicationTileBanner.fields.tilesPerPage;
    
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState(contentTile);
    const includeColumns = ["shortDescription", "title"];
    const [listFiltered, setListFiltered] = useState([]);
    const [searchedData, setSearchData] = useState(contentTile);
    const [currentPage, setCurrentPage] = useState(1);

    const handleSearchChange = value => {
        setSearchText(value);
        filterData(value);
    };

    const handleChangeFilter = (event) => {
        let value = event.target.value;
        tileFilter.forEach(element => {
            if(element.value.replace(/[^a-zA-Z ]/g, "") == event.target.value.replace(/[^a-zA-Z ]/g, "")) {
                event.target.className == 'buttonWhite' ? event.target.className = 'button' + element.backgroundColor : event.target.className = 'buttonWhite';
            }
        });
        
        setListFiltered(nums => nums.includes(value.replace(/[^a-zA-Z ]/g, "")) ? nums.filter(n => n !== value.replace(/[^a-zA-Z ]/g, "")) : [value.replace(/[^a-zA-Z ]/g, ""), ...nums]); 
    }

    useEffect(() => {
        //Get filtered contenttile
        let filteredContentTile = contentTile.filter((tile) => {
            if(listFiltered.length !== 0){
                return isDataSelected(tile, listFiltered, "categorytag");
            }else
            {
                return true;
            }
        });
        //Get filtered and searched contenttile
        let finalData = filteredContentTile.filter((tile) => {
            return isSearchContains(tile,searchedData);
        });
       setCurrentPage(1);
       setData(finalData);
    },[listFiltered,searchedData]); 

    const currentTableData = useMemo(() => {
        
        data.sort((a, b) => new Date(b.fields.publicationDate) - new Date(a.fields.publicationDate) || a.fields.title.localeCompare(b.fields.title));
        if(PageSize < data.length){
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            return data.slice(firstPageIndex, lastPageIndex);
        }
        else{
            return data.slice(0, data.length);
        }
      }, [currentPage,data]);

    const filterData = (value) => {
        const lowercasedValue = value.replace(/[^[a-zA-Z0-9]{8,}$]/g, "").toLowerCase().trim();
        if (lowercasedValue === "" || lowercasedValue === "all") {
            
            setSearchData(contentTile);
        }
        else {
            const filteredData = contentTile.filter(item => {
                return Object.keys(item.fields).some(key => 
                    includeColumns.includes(key) ? item.fields[key].replace(/[^[a-zA-Z0-9]{8,}$]/g, "").toString().toLowerCase().includes(lowercasedValue) : false 
                );
            });
            setSearchData(filteredData);
        }
    }

    const handleClearFilter = () => {
        setSearchText("");
        filterData("");
        setListFiltered("");

        const buttonFilters = [...document.getElementsByClassName("multi-button flex-wrap")[0].children];
        buttonFilters.forEach(button => {
            button.className =  "buttonWhite";
        });
    }

    return (

        <>
        <div className="container contentFilter">
            <div className = "container tile-container filter-heading">Selecting the buttons will filter the resource tiles below according to category. If you have resources you would like to submit, please email the <a href="mailto:ksenia.koon@gatesfoundation.org"><span>CTVD Program Manager.</span></a></div>

            <div className="multi-button flex-wrap">
                {tileFilter.map((item, index)=>{
                        return <button className="buttonWhite" style={{'--color': item.backgroundColor}} key={index} value={item.value} onClick={handleChangeFilter}>{item.value}</button>
            
                })}
            </div>
            
            <hr />
            <br/>
            <div className="container" align='left'>
            <div className="searchGroup">
                Search Resources: <input style={{ marginLeft: 5 }} type="text" placeholder="Enter search term" value={searchText}
                 onChange={e => handleSearchChange(e.target.value)} />
                <i className="bi bi-search"></i>
                
            </div>
            <button className="clearfilterBtn" onClick={handleClearFilter}>Clear Filters &#10006;</button> 
            </div>
        </div>
        <br/>
        
            <div className= {"publication-tile-banner " +bannerTileTemplate+ " "+ backgroundColor}
                style={{  
                    backgroundImage: (backgroundImage ? "url(" + backgroundImage + ")" : ""),
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
                >
                <div className="container"> 
                    <div className="tile-container">
                        <h1 className = "heading">{header}</h1>
                        <h3 className = "subheading">{subheading}</h3>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(paragraph) }}></div>
                        <Row xs={1} md={tilePerRow > 1 && tileTempalte == 'text-only' || 'large-image' ? 2 : 1}  lg={tilePerRow > 2 ? (tileTempalte == 'text-only' || tileTempalte =='text-only-2' || tileTempalte =='large-image'||  tileTempalte =='icon' || tileTempalte ==='image-no-shadow' || tileTempalte ==='left-image'? 3 : 3) :2} xl={tilePerRow}>
                            {
                                currentTableData && <> 
                                    {currentTableData.map((content, i)=>{  
                                        return (
                                            <Col key={"tile-"+i}> <ContentTile contentTile = {content} template = {tileTempalte} config = {props.config} />   </Col>                              
                                        )
                                    })}    
                                        
                                </> 
                            } 
                        </Row>
                        {link && <CTA url={link.fields.url} className={"banner-link " + link.fields.type} > {link.fields.label} </CTA>} 
                    </div>
                    {image && 
                    <div className="img-container">
                        <img className="img-fluid" src={image.fields.file.url} alt={image.fields.title} />
                    </div>
                    } 

             

                </div>    
                {!data || data.length === 0 ? <p className="no-results">No resources available</p>: null}  
            </div>
            <Pagination
                className="pagination-bar text-xs-center"
                currentPage={currentPage} 
                totalCount={data.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            <ScrollToTopButton/>
        </>
    );
}