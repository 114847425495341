import React, {useState, useEffect} from 'react';
import { CMSService } from "@vaccinessites/services";
import {CTA} from '../CTA/CTA';
import './ContentTile.scss';
import {InnerTile} from './InnerTile';

export function ContentTile (props) {
    
    let contentTile = props.contentTile.fields;
    let subheading = contentTile.subheading;
    let heading = contentTile.heading;
    let template = props.template ? props.template : contentTile.template;
    let image = contentTile.image ? contentTile.image : null;
    let overlayImage = contentTile.overlayImage ? contentTile.overlayImage : null;
    let link = contentTile.link ? contentTile.link : null;
    let backgroundColor = contentTile.backgroundColor ? contentTile.backgroundColor.toLowerCase() : null;
    let footer = contentTile.footer;

    let imageBanner = contentTile.imageBanner ? contentTile.imageBanner : null;
    let title = contentTile.title;
    let categorytag = contentTile.categorytag;
    let slug = contentTile.slug ? contentTile.slug : null;
    let externalCta = contentTile.externalCta ? contentTile.externalCta : null;
    let tagColor = contentTile.tagColor;
    let fontColor = contentTile.fontColor;
    let contentType = props.contentTile.sys ? props.contentTile.sys.contentType.sys.id : null;

    return (
        <>
            {contentType == "publication" ? 
                <div className= {"content-tile " + template + " " +backgroundColor}>
                    {template != 'large-image' && template != 'small-image' && template != 'wide-image' &&
                    <div className="container">
                        
                        {imageBanner && 
                            <div className="img-container">
                                <img className="publication-img-fluid" src={imageBanner.fields.file.url} alt={imageBanner.fields.title} />
                            </div>
                        }
                        <div className = "text-container">
                            {title && <h5 className = "heading"> {title} </h5>}
                            {categorytag && <div className = "subheading" style={{backgroundColor: tagColor, color: fontColor}}> {categorytag}</div>}
                            {contentTile.shortDescription && <p className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(contentTile.shortDescription) }}></p>}
                            {link  && link.fields && <CTA url={link.fields.url} className={link.fields.type + " tile-cta"}> {link.fields.label}</CTA>}
                            {footer && <div className = "footer"> {footer}</div>}
                        </div>
                    </div>
                    }
                    {(template === 'large-image' || template === 'small-image' || template === 'wide-image') && 
                        <>
                        {slug && !externalCta ? 
                            <>
                                <InnerTile imageBanner={imageBanner && imageBanner.fields}
                                    imgOverlaySrc={overlayImage && overlayImage.fields.file.url} imgOverlayAlt={overlayImage && overlayImage.fields.title}
                                    title={title} categorytag={categorytag} tagColor={tagColor} fontColor={fontColor} contentType={contentType} url={'/publication/'+ slug} shortDescription={contentTile.shortDescription} footer={contentTile.footer} config={props.config}/>
                             </> :
                            <>
                                <InnerTile imageBanner={imageBanner && imageBanner.fields}
                                    imgOverlaySrc={overlayImage && overlayImage.fields.file.url} imgOverlayAlt={overlayImage && overlayImage.fields.title}
                                    title={title} categorytag={categorytag} tagColor={tagColor} fontColor={fontColor} contentType={contentType} url={externalCta.fields.url} shortDescription={contentTile.shortDescription} footer={contentTile.footer} config={props.config}/>
                            </>
                        }
                        </>
                    }
                </div> : 

                <div className= {"content-tile " + template + " " +backgroundColor}>
                    {template != 'large-image' && template != 'small-image' && template != 'wide-image' &&
                    <div className="container">
                        {image && 
                            <div className="img-container">
                                <img className="img-fluid" src={image.fields.file.url} alt={image.fields.title} />
                            </div>
                        }
                        <div className = "text-container">
                            {heading && <h5 className = "heading"> {heading} </h5>}
                            {subheading && <div className = "subheading"> {subheading}</div>}
                            {contentTile.text && <p className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(contentTile.text) }}></p>}
                            {link  && link.fields && <CTA url={link.fields.url} className={link.fields.type + " tile-cta"}> {link.fields.label}</CTA>}
                            {footer && <div className = "footer"> {footer}</div>}
                        </div>
                    </div>
                    }
                    {(template === 'large-image' || template === 'small-image' || template === 'wide-image') && 
                        <>
                        {link ? 
                            <>
                                <InnerTile image={image && image.fields}
                                    imgOverlaySrc={overlayImage && overlayImage.fields.file.url} imgOverlayAlt={overlayImage && overlayImage.fields.title}
                                    heading={heading} subheading={subheading} text={contentTile.text} contentType={contentType} url={contentTile.link.fields.url} footer={contentTile.footer}/>
                            </> :
                            <>
                                <InnerTile image={image && image.fields}
                                    imgOverlaySrc={overlayImage && overlayImage.fields.file.url} imgOverlayAlt={overlayImage && overlayImage.fields.title}
                                    heading={heading} subheading={subheading} text={contentTile.text} contentType={contentType} footer={contentTile.footer}/>
                            </>
                        }
                        </>
                    }
                </div>
            }
        </>
    );
}
