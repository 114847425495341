import React from 'react';
import {Link} from 'react-router-dom';
import { isInternalLink } from '../../utils';

export function CTA(props){
    return (

        isInternalLink(props.url)  ? 
            <Link to={props.url} className={props.className}>{props.children}</Link> :
        
            <a href={props.url} target="_blank" className={props.className}>{props.children}</a>
        
    )
}