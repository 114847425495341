import { SharepointService } from '@vaccinessites/services';
import {useState, useEffect} from 'react';

export class TabbedListData{
        constructor(datasource, CMS, baseAPIUrl){
        this.datasource = datasource;
        this.CMS = CMS;
        const Sharepoint = new SharepointService(baseAPIUrl, this.datasource);
        this.Sharepoint = Sharepoint;
        const [results, setResults] = useState();
        this.setResults = setResults;
        this.results = results;
        this.groupedList = [{
            id: "af",
            label: "A - F",
            firstLetter: 'a',
            lastLetter: 'f',
            items: {
                a: [],
                b: [],
                c: [],
                d: [],
                e: [],
                f: []
            }
        },{
            id: "gk",
            label: "G - K",
            firstLetter: 'g',
            lastLetter: 'k',
            items: {
                g: [],
                h: [],
                i: [],
                j: [],
                k: []
            }
        },
        {
            id: "lp",
            label: "L - P",
            firstLetter: 'l',
            lastLetter: 'p',
            items: {
                l: [],
                m: [],
                n: [],
                o: [],
                p: []
            }
        }, {
            id: "qu",
            label: "Q - U",
            firstLetter: 'q',
            lastLetter: 'u',
            items: {
                q: [],
                r: [],
                s: [],
                t: [],
                u: []
            }
        }, {
            id: "vz",
            label: "V - Z",
            firstLetter: 'u',
            lastLetter: 'z',
            items: {
                v: [],
                w: [],
                x: [],
                y: [],
                z: []
            }
        }]
    }
    getData(){
        if(this.datasource  === "Active Grants"){
            return this.getFromActiveGrants()
        }
        if(this.datasource === "Former Grants"){
            return this.getFromFormerGrants()
        }
        if(this.datasource === "CAVD Institutions"){
            return this.getFromCAVDInstitutions()
        }
        if(this.datasource === "GHDC Institutions"){
            return this.getFromGHDCInstitutions()
        }
    }
    async getFromGHDCInstitutions(){
        return this.Sharepoint.getDataFromDataSource().then((institutions)=>{
        let listItems = this.groupedList;
           institutions = institutions.data.sort((a,b)=> {
                    return a.fields.institution.localeCompare(b.fields.institution)
                })
            institutions.forEach((insti)=>{
                let firstChar = insti.fields.institution[0].toLowerCase();
                let navTabIndex = listItems.findIndex(navTab=>{
                    return firstChar >= navTab.firstLetter && firstChar <= navTab.lastLetter
                });
                if (navTabIndex >=0) {
                    listItems[navTabIndex].items[firstChar].push(insti)   
                }
            })
            return listItems;
        });
    }
    async getFromCAVDInstitutions(){
        return this.Sharepoint.getDataFromDataSource().then((institutions)=>{
        let listItems = this.groupedList;
           institutions = institutions.data.sort((a,b)=> {
                    return a.fields.title.localeCompare(b.fields.title)
                })
            institutions.forEach((institution)=>{
                let firstChar = institution.fields.title[0].toLowerCase();
                let navTabIndex = listItems.findIndex(navTab=>{
                    return firstChar >= navTab.firstLetter && firstChar <= navTab.lastLetter
                });
                if (navTabIndex >=0) {
                    listItems[navTabIndex].items[firstChar].push(institution)   
                }
            })
            return listItems;
        });
    }
    async getFromFormerGrants(){
        let listItems = this.groupedList;
        let grants = await this.CMS.getGrantsByActiveStatus(false);
        if (grants) {
            grants = grants.sort((a,b)=>{
                return a.fields.grantTitle.localeCompare(b.fields.grantTitle);
            })
            grants.forEach((grant)=>{
                let firstChar = this.getFirstCharacter(grant.fields.grantTitle);
                let navTabIndex = listItems.findIndex(navTab=>{
                    return firstChar >= navTab.firstLetter && firstChar <= navTab.lastLetter
                });
                listItems[navTabIndex].items[firstChar].push(grant)
            })
        }
        return listItems;
    }
    async getFromActiveGrants(){
        let listItems = this.groupedList;
        let grants = await this.CMS.getGrantsByActiveStatus(true);
        if (grants) {
            grants = grants.sort((a,b)=>{
                return a.fields.grantTitle.localeCompare(b.fields.grantTitle);
            })
            grants.forEach((grant)=>{
                let firstChar = this.getFirstCharacter(grant.fields.grantTitle);
                let navTabIndex = listItems.findIndex(navTab=>{
                    return firstChar >= navTab.firstLetter && firstChar <= navTab.lastLetter
                });
                listItems[navTabIndex].items[firstChar].push(grant)                
            })
        }
         return listItems;
    }
    getFirstCharacter(title){
        return title.toLowerCase().replace(/\W/g, '')[0]
    }
}