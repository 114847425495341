import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
      // if not a hash link scroll to top
      if (hash === '') {
          window.scrollTo(0, 0)
      }
      // else scroll to id
      else {
          setTimeout(
              () => {
                  let element = document.getElementById(hash.replace('#', ''));
                  if (element) {
                    window.scrollTo({ top: element.getBoundingClientRect().top + window.pageYOffset - 200 });
                  }
              },
              0
          );
      }
  }, [pathname, hash]);

  return null;
}