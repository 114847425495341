import React, {useState, useEffect} from 'react';
import { SharepointService } from '@vaccinessites/services';
import { ContentTileBanner } from '../ContentTileBanner/ContentTileBanner';

export function SharepointContentTileBanner (props){
    const banner = props.banner.fields;
    const Sharepoint = new SharepointService(props.config.baseAPIUrl, banner.dataSource);
    const [results, setResults] = useState([])
    useEffect(()=>{
        Sharepoint.getDataFromDataSource({
            numberOfResults: banner.numberOfResults
        }).then((data)=>{
            let formattedResults = data.data && data.data.map(dataItem=>{
                let item = dataItem.fields;
                return {
                    fields: {
                        heading: item.title,
                        text: `<p><i>${item.journalName}</i></p><p>${item.firstAuthor || item.authors} ${item.authors ? `<i>et al.</i>`: item.firstAuthor.includes(',') ? `<i>et al.</i>`: ``}</p><br/><p>PMID: ${item.pmid}</p><p>${(item.publishDate && new Date(item.publishDate).toLocaleDateString('en-us')) || item.publicationYear}</p>`,
                        link: {
                            fields: {
                                label: banner.tileLinkText,
                                url: (item.linkToFullText && item.linkToFullText.url) || (item.link && item.link.url),
                                type: 'text-only'
                            }
                        }
                    }
                }
            })
            setResults(formattedResults)
        })
    }, [])
    return (
        <ContentTileBanner contentTileBanner={{fields:{
            backgroundColor: banner.backgroundColor,
            heading: banner.heading,
            cta: banner.cta,
            bannerTemplate: banner.bannerTemplate,
            tilesPerRow: banner.tilesPerRow,
            tileTemplate: 'text-only',
            contentTile: results 
        }}}/>
    )
}