import React, { Component, useState } from 'react';
import { CMSService } from "@vaccinessites/services";
import {CTA} from '../CTA/CTA';
import './Banner.scss';

export function Banner (props) {
    let image = props.banner.fields.image ? props.banner.fields.image : null;
    let icon = props.banner.fields.icon ? props.banner.fields.icon : null;
    let backgroundColor = props.banner.fields.backgroundColor ? props.banner.fields.backgroundColor.toLowerCase().replace(' ', '') : null;
    let link = props.banner.fields.link;
    let textAlignment = props.banner.fields.textAlignment ? props.banner.fields.textAlignment : "left";
    let heading = props.banner.fields.heading;
    
    return(
            <div className={"banner " + props.banner.fields.template + " " + backgroundColor + " " + textAlignment}>
                <div className="container">
                    <div className="row">
                        <div className={"text-container col-12 "+ (image && "col-lg-5")}>
                            <div className="column-1">
                                {icon &&
                                <div className="icon-container col-12 col-lg-7">
                                    <img className="img-icon" src={icon.fields.file.url} alt={icon.fields.title}/>
                                    </div>}
                                {heading && <h1 className="heading">{heading}</h1>}
                            </div>
                            <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.banner.fields.paragraph) }}></div>
                            {link && <CTA url={link.fields.url} className={'cta ' + link.fields.type} > {link.fields.label} </CTA>} 
                            {props.children}
                        </div>
                        {image && 
                        <div className="img-container col-12 col-lg-7">
                            <img className="img-fluid" src={image.fields.file.url} alt={image.fields.title} />
                        </div> }
                    </div>
                </div>
            </div>
    )
}