import React, {useState, useEffect} from 'react';
import './JSONList.scss'

export function JSONList(props){
    let content = props.JSON.fields;
    let jsonData = content.json;
    return (
        <div className="container jsonList">
            <h2>{content.listHeading}</h2>
            {jsonData.map(item=>{
                if(item['External URL']){
                    return <CSVItem item={item} />
                } else {
                    return <JSONItem item={item} content={content}/>
                }
            })}
            <hr />
        </div>
    )
}

function JSONItem(props){
    let item = props.item;
    let content = props.content;
    const externalIdLink = item.external_ids && item.external_ids.find(id=>id.type===content.resultLinkId);
    let sourceUrl = item.source_urls && item.source_urls.length > 0 && item.source_urls[0].url;
    let url = null;
    if(sourceUrl) {
        //preferentially use sourceUrl over external Id 
        url = sourceUrl;
    } else if (externalIdLink) {
        url = content.resultBaseLink + externalIdLink.value
    } 
    const sourceTitle = item.source && item.source.title_full;
    let date = item.date_published && item.date_published.date;
    date = new Date(date).toLocaleDateString('en-us',{month: "long", year: "numeric"});
    return (
    <div className="jsonListItem" key={item.lens_id}>
        <h3><a href={url} target="_blank">{item.title}</a></h3>
        <p className="italics">{item.authors && item.authors.map((author, i)=>{
            return <span key={author.initials + i}>{author.first_name + ' ' +author.last_name + (i!==item.authors.length - 1 ? ', ' : '')}</span>
        })}</p>
        <p>{sourceTitle} {date}</p>
    </div>
    )
}

function CSVItem(props){
    let item = props.item;
    return (
        <div className="jsonListItem" key={item["Lens ID"]}>
            <h3><a href={item["External URL"]} target="_blank">{item["Title"]}</a></h3>
            <p className="italics">{item["Author"] && item["Author"]["s"]}</p>
            <p>{item["Source Title"]} {item["Date Published"]}</p>
        </div>
    )
}