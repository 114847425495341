import React, { Component, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {CTA} from '../CTA/CTA';
import './Modal.scss';



export function PublicationsModal (props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let link=props.modal.linkToFullText;
  let date = props.modal.publishDate && props.modal.publishDate;
  date = new Date(date).toLocaleDateString('en-us',{day: "numeric", month: "numeric", year: "numeric"});
  return (
      <>
        <Link onClick={handleShow}> 
          {props.modal.title}
        </Link>
  
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title> Publications - {props.modal.title} </Modal.Title>
            <button type="button" class="bi-x-lg" aria-label="close" onClick={handleClose}></button>
          </Modal.Header>
          <Modal.Body>
            <h5>First Author</h5>
            {props.modal.firstAuthor && <p>{props.modal.firstAuthor}</p>}
            <h5>Authors</h5>
            {props.modal.authors && <p>{props.modal.authors.replace( /(<([^>]+)>)/ig, ' ').replace(/&#[0-9]+;/g, ' ')} </p>}
            <h5>CAVD Grantee</h5>
            {props.modal.cavdGrantee && <p>{props.modal.cavdGrantee}</p>}
            <h5>Journal Name</h5>
            {props.modal.journalName && <p>{props.modal.journalName}</p>}
            <h5>Publication Date</h5>
            {date && <p>{date}</p>}
            <h5>Pub Med Search</h5>
            {props.modal.pubMedSearch && <p><CTA url={props.modal.pubMedSearch.url}>{props.modal.pubMedSearch.description}</CTA></p>}
            <h5>Link to full-text</h5>
            {props.modal.linkToFullText && <p><CTA url={props.modal.linkToFullText.url}>{props.modal.linkToFullText.description}</CTA></p>}
            <h5>PMID</h5>
            {props.modal.pmid && <p>{props.modal.pmid}</p>}
            <h5>DOI</h5>
            {props.modal.doi && <p>{props.modal.doi}</p>}
            <h5>Abstract</h5>
            {props.modal.abstract && <p>{props.modal.abstract.replace( /(<([^>]+)>)/ig, ' ').replace(/&#[0-9]+;/g, ' ')}</p>}
            <h5>Collaborating Institutions</h5>
            {props.modal.collaboratingConsortia && <p>{props.modal.collaboratingConsortia}</p>}
            <h5>Year</h5>
            {props.modal.publicationYear && <p>{props.modal.publicationYear}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}