import React, {useState, useEffect} from 'react';
import {CTA} from '../CTA/CTA';
import './ArrowLink.scss';

export function ArrowLink (props) {
    let arrow = props.arrowLink.fields;
    let link = arrow.cta;
    return (
        <div className="arrow-container">
            <div className="container">  
                <div className={"arrow-tag " + arrow.type}> {arrow.type == "left" ? "Back": "Next"} </div>  
            
                {link && <CTA url={link.fields.url} className={link.fields.type + " " + arrow.type} > 
                {arrow.type == "left" ? <i className={"bi bi-chevron-" + arrow.type}></i> : null}                    
                <span className="arrow-title">{arrow.title} </span>
                {arrow.type == "right" ? <i className={"bi bi-chevron-" + arrow.type}></i> : null}   
                </CTA>}  
            </div>
        </div>
    )
}