import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Banner } from '../Banner/Banner';
import { TabbedListData } from './TabbedListData';
import './TabbedList.scss';

export function TabbedList (props) {
    let list=props.tabbedList.fields;
    const [listItems, setListItems] = useState();
    let tabData = new TabbedListData(list.dataSource, props.CMS, props.config.baseAPIUrl);
    useEffect(()=>{
        tabData.getData().then(data=>{
            setListItems(data)
        });
    }, [])

    function handleNavBehavior(e){
        if(list.navBehavior === "Anchor Links"){
            return;
        } else if (list.navBehavior==="Tabs"){
            //todo fill out behavior here
        }
    }

    return (
        <div className="tabbed-list">
            <div className="container">
                <Banner banner={{fields: {
                    template: 'none-one-column',
                    heading: list.heading,
                    backgroundColor: list.bannerBackgroundColor,
                    paragraph: list.description
                }}}/>
            </div>
            <div className="nav-list">
                <div className="container">
                    {listItems && listItems.map(nav=>{
                        return <a href={"#"+nav.id} onClick={handleNavBehavior} key={nav.id}>{nav.label}</a>
                    })}
                </div>
            </div >
            <div className="container list-container">
                
            {listItems && 
                listItems.map(group=>{
                    return (
                        <div id={group.id} key={group.id} className="link-grouping">
                            <h2>{group.label}</h2>
                            {Object.keys(group.items).map((letter)=>{
                                let links = group.items[letter];
                                return (
                                    links.length > 0 && <div key={letter}>
                                    {links.map(link=>{
                                        let isLink = link.fields.slug;
                                        let isExternalLink = link.fields.externalCta && link.fields.externalCta.fields.url ? link.fields.externalCta.fields.url : null;
                                        
                                        if (isLink) {
                                        return <>{link && 
                                            <Link to={'/grants/'+isLink} key={isLink} className='tabbed-link' >
                                                    {link.fields.grantTitle}
                                            </Link>}</>
                                            }
                                        else if(isExternalLink) {
                                            return <>{link && 
                                                <a href={isExternalLink} className='tabbed-link' target='_blank' >{link.fields.grantTitle}</a>}</>
                                        }
                                        else{
                                            let source = list.dataSource;
                                            if (source === "CAVD Institutions"){
                                            return <> {link &&
                                                <p className="tabbed-link">
                                                    {link.fields.clinicalTrialsAddendum ? link.fields.title+'*' : link.fields.title}
                                                </p> 
                                            }</>}
                                            else if (source === "GHDC Institutions") {
                                                return <> {link &&
                                                    <p className="tabbed-link">
                                                        {link.fields.institution+ ", "+link.fields.location}
                                                    </p> 
                                                }</>
                                            }
                                        }
                                        }
                                    )}
                                </div>)
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}