import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { Html5Entities } from 'html-entities';

export const CMSService = {
    default: {},    
    content: {},
    initialize(defaultContent){
        this.default = defaultContent;
        return this;
    },
    async checkContent(){
        let siteContent = this.getWithExpiry("siteContent");
        
        if(!siteContent || !siteContent.items){
            siteContent = await fetch('/content/contentful-content.json').then(result => result.json());
            this.content = this.setCacheWithExpiry("siteContent", siteContent, 1800000);
        }
        return siteContent
    },
    async getAllByContentType(contentType){
        let content = await this.checkContent()
        let allContentType = content.items.filter(item=>{
            return item.sys.contentType.sys.id === contentType;
        })
        return allContentType
    },
    async getGrantsByActiveStatus(isActive){
        let grants = await this.getAllByContentType('grant');
        grants = grants.filter(grant=> {
            return grant.fields.active === isActive
        })
        return grants
    },
    getGrant(slug){
        return this.checkContent()
        .then((data) => {
            let content = data || this.default;
            let grant = content.items.find(item=>{
                if(item.sys.contentType.sys.id==="grant"){
                    return(item.fields.slug === slug)
                }
            })
            return grant;
        })
    },
    getPublication(slug){
        return this.checkContent()
        .then((data) => {
            let content = data || this.default;
            let publication = content.items.find(item=>{
                if(item.sys.contentType.sys.id==="publication"){
                    return(item.fields.slug === slug)
                }
            })
            return publication;
        })
    },
    getContent(route){
        return this.checkContent()
        .then((data) => {
            this.content = data || this.default;
            return this.getPage(this.content, route);
        });
    },
    getPage(siteContent, pageRoute){
        let page = siteContent.items.find(item=>{
            if(item.sys.contentType.sys.id==="page"){
                return item.fields.route.replace('*', item.fields.slug).toLowerCase() === pageRoute.toLowerCase();
            }
        })
        return page;
    },
    getHeader(){
        return this.checkContent()
        .then((data) => {
            let content = data || this.default
            let header = content.items.find(item=>{
                return item.sys.contentType.sys.id==="header";
            })
            header=this.formatHeader(header);
            return header;
        });
    },
    setCacheWithExpiry(key, value, ttl) {
        const now = new Date()

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)

        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    },
    formatHeader(header){
        let nav = {
            brandImg: header.fields.brandLogo.fields.file.url,
            brandAlt: header.fields.brandLogo.fields.description,
        }
        let links = header.fields.links.map(link=>{
            let linkObj= {
                title: link.fields.label, 
                path: link.fields.url
            }
            if(link.fields.dropdownChildren){

                linkObj.children= link.fields.dropdownChildren.map(childLink=>{
                    if(childLink.fields.dropdownChildren)
                    {
                        return {title: childLink.fields.label, path: childLink.fields.url, 
                                children: childLink.fields.dropdownChildren.map(subchildlink =>{
                                return {title: subchildlink.fields.label, path: subchildlink.fields.url}})}
                        
                    }
                    else{
                        return {title: childLink.fields.label, path: childLink.fields.url}
                    }
                })
            }
            return linkObj                    
        })
        nav.links=links;
        return nav;
    },
    getFooter(){
        return this.checkContent()
        .then((data) => {
            let content = data || this.default
            let footer = content.items.find(item=>{
                return item.sys.contentType.sys.id==="footer";
            })
            footer=this.formatFooter(footer);
            return footer;
        });
    },
    formatFooter(footer){
        let newFooter= Object.assign(footer.fields,{
            logo: footer.fields.logo.fields.file.url,
            logoAlt: footer.fields.logo.fields.description,
        })
        let footerLinks = footer.fields.footerLinks.map(link=>{
            let linkObj= {
                title: link.fields.label, 
                path: link.fields.url
            }            
            return linkObj                    
        })
        newFooter.footerLinks=footerLinks;
        return newFooter;
    },
    getRichText(text){
        if(typeof text === 'string'){
            return text;
        }
        const htmlEntities = new Html5Entities();
        const options = {
            renderNode: {
                [INLINES.HYPERLINK]: (node, next) => {
                    const isSameOrigin = node.data.uri.startsWith(window.location.origin) || node.data.uri.startsWith('/');
                    const isMailLink = node.data.uri.startsWith('mailto:');
                    const opensInNewTab = isSameOrigin && !isMailLink;
                    return `<a href="${node.data.uri}" ${opensInNewTab ? '' : ' target="_blank" noopener noreferrer aria-label="'+next(node.content)+' (opens in a new tab)"'} >${next(node.content)}</a>`;
                },
                [INLINES.ASSET_HYPERLINK]: (node,next)=>{
                    return `<a href="${node.data.target.fields.file.url}" target="_blank" noopener noreferrer aria-label="'+next(node.content)+' (opens in a new tab)"'} >${next(node.content)}</a>`;
                },
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                    // render the EMBEDDED_ASSET as you need
                    return (
                      `<img class="img-fluid"
                        src=https://${node.data.target.fields.file.url}
                        height=${node.data.target.fields.file.details.image.height}
                        width=${node.data.target.fields.file.details.image.width}
                        alt=${node.data.target.fields.description}
                      />`
                    );
                },
            },
        }
        let html = documentToHtmlString(text, options);
        //need to replace non-breaking spaces as Contentful can add them if copy and pasting content into rich text field
        html = htmlEntities.encode(html).replace(/&nbsp;/g, " ");
        return htmlEntities.decode(html);
    }
}
