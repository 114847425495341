export class AccordionListData{
    constructor(startingIndex, datasource, CMS){
        this.startingIndex = startingIndex;
        this.datasource = datasource;
        this.CMS = CMS;
        this.groupedList = [];
    }
    
    getData(){
        if(this.datasource  === "Former Grants"){
            return this.getFromFormerGrantsByYear()
        }
    }

    async getFromFormerGrantsByYear() {
        let listItems = this.groupedList;
        let grants = await this.CMS.getGrantsByActiveStatus(false);
        let awardYearCounter = this.startingIndex;
        let currentYear = new Date().getFullYear();

        while (awardYearCounter <= currentYear) {
            let grantsList = [];
            grantsList = grants.filter(function(grant) {
                return grant.fields.awardYear == awardYearCounter;
              })
              .map(function(grant) {
                  let link = grant.fields.externalCta != null? grant.fields.externalCta.fields.url : null;
                 return ({grantTitle: grant.fields.grantTitle, slug: grant.fields.slug, externalCta: link});                 
              });
            if(grantsList.length){
                listItems.unshift({ year: awardYearCounter, grants: grantsList, count: grantsList.length});
             }
             else{
                grantsList.push({grantTitle: 'No grants to show.'})
                listItems.unshift({ year: awardYearCounter, grants: grantsList, count: 0 });
             }
             awardYearCounter++;
        }
        return listItems;        
    }
}