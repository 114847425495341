import React, {useState, useEffect} from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Banner } from '../Banner/Banner';
import { AccordionListData } from './AccordionListData';
import './AccordionList.scss';
import {CTA} from '../CTA/CTA';

export function AccordionList (props) {
    let list=props.accordionList.fields;
    const [listItems, setListItems] = useState();
    let listData = new AccordionListData(list.startingIndex, list.dataSource, props.CMS)
    useEffect(()=>{
        listData.getData().then(data=>{
            setListItems(data)
        });
    }, [])

    return (
        <div className="accordion-list">
            <div className="container">
                <Banner banner={{fields: {
                        template: 'none-one-column',
                        heading: list.heading,
                        backgroundColor: list.bannerBackgroundColor,
                        paragraph: list.description ? list.description : ""
                }}}/>
            </div>
            <div className="container accordion-container">
                    {listItems && 
                        listItems.map((group, i)=>{
                            return (
                                <div>
                                    <Accordion defaultActiveKey={group.year}>
                                        <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey={group.year}>
                                                {group.year} ({group.count})
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={group.year}>
                                            <Card.Body>
                                                {group.grants.map(grant=>{
                                                    if (grant.slug != null) {
                                                        return(
                                                            <div key={grant.year}>
                                                                { 
                                                                    <Link to={'/grants/'+ grant.slug} key={grant.slug}>
                                                                            {grant.grantTitle}
                                                                    </Link> 
                                                                }           
                                                            </div> 
                                                        )
                                                    }
                                                    else if (grant.externalCta != null){
                                                        return(
                                                            <div key={grant.year}>
                                                                { 
                                                                    <CTA url={grant.externalCta}> {grant.grantTitle} </CTA>
                                                                }           
                                                            </div> 
                                                        )
                                                    }
                                                    else {
                                                        return(
                                                            <div key={grant.year}>
                                                                { 
                                                                    grant.grantTitle
                                                                }           
                                                            </div> 
                                                        )
                                                    }
                                                })} 
                                            </Card.Body>
                                         </Accordion.Collapse>                                       
                                        </Card>
                                    </Accordion>
                                </div>
                            )
                    })}
            </div>
        </div>
    )
}