import React, { useEffect, useState } from 'react';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { WideContentTile } from '../WideContentTile/WideContentTile';
import '../Banner/Banner.scss';
import './WideCarouselBanner.scss';


function generateSliderGroupings(widecarousel, slides){
    let allSlidesIdx= [...Array(widecarousel.details().size).keys()]
    let views=[];
    //split number of slides into groupings so we can find which slide is the current slide
    while(allSlidesIdx.length) {
        if(allSlidesIdx.length >= slides){
            views.unshift(allSlidesIdx.splice(allSlidesIdx.length-slides,slides));
        } else {
            views.unshift(allSlidesIdx.splice(0, slides))
        }
    }
    return views;
}

function ArrowLeft(props) {
  return (    
    <i onClick={props.onClick} class="bi-arrow-left arrow arrow--left"></i>
  )
}

function ArrowRight(props) {
  return (
    <i onClick={props.onClick} class="bi-arrow-right arrow arrow--right"></i>
  )
}

export function WideCarouselBanner (props){
    let widecarousel = props.widecarousel.fields;
    let backgroundColor = widecarousel.backgroundColor && widecarousel.backgroundColor.toLowerCase().replace(' ', '');
    let carouselTimer = widecarousel.carouselTimer * 1000;
    const [pause, setPause] = React.useState(false)
    const timer = React.useRef()
    const [sliderRef, slider] = useKeenSlider({
        loop: true,
        duration: 300,
        dragStart: () => {
          setPause(true)
        },
        dragEnd: () => {
          setPause(false)
        },  slideChanged(s) {
        setCurrentSlide(s.details().relativeSlide);
      }, mounted(c){
        setNumSlidesPerView(c.details().slidesPerView)
      }});
      React.useEffect(() => {
        sliderRef.current.addEventListener("mouseover", () => {
          setPause(true)
        })
        sliderRef.current.addEventListener("mouseout", () => {
          setPause(false)
        })
      }, [sliderRef])
      React.useEffect(() => {
        timer.current = setInterval(() => {
          if (!pause && slider) {
            slider.next()
          } 
        }, carouselTimer)
        return () => {
          clearInterval(timer.current)
        }
      }, [pause, slider])
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [numSlidesPerView, setNumSlidesPerView] = useState(0);
    let sliderDots = slider && [...Array(Math.ceil(slider.details().size / numSlidesPerView)).keys()];
    let sliderGroupings = slider && generateSliderGroupings(slider, numSlidesPerView);

    return(
        <div className={"wide-carousel-banner banner " + backgroundColor}
        >
            <div className="container">
                <div ref={sliderRef} className="row wide-carousel-row" className="keen-slider">
                    {widecarousel.wideContentTiles.map((tile, i)=>{
                        return (
                            tile.fields && <div key={'tile-'+i} className={"col-12 col-md-6 col-lg-4 wide-carousel-tile keen-slider__slide number-slide"+i} 
                                onBlur={()=>{
                                    slider.moveToSlide(i)
                                }}>
                                <WideContentTile contentTile={tile} template={widecarousel.contentTileTemplate}/>
                            </div>
                        )
                    })}
                </div>

                {slider && (
                    <div className="dots">
                    {
                    sliderDots.map((idx) => {
                        let slideNum = idx * numSlidesPerView;

                        let activeDotIdx = (sliderGroupings.findIndex(i=>{
                            return i.includes(currentSlide)
                        }))
                        
                        return (
                        <button
                            key={idx}
                            onClick={() => {
                                slider.moveToSlide(slideNum);
                            }}
                            aria-label={"navigate to slide "+ slideNum}
                            className={"dot" + ( idx=== activeDotIdx ? " active" : "")}
                        />
                        );
                    })}
                    </div>
                )}

                {slider && (
                  <>
                    <ArrowLeft
                      onClick={(e) => e.stopPropagation() || slider.prev()}
                      disabled={currentSlide === 0}
                    />
                    <ArrowRight
                      onClick={(e) => e.stopPropagation() || slider.next()}
                      disabled={currentSlide === slider.details().size - 1}
                    />
                  </>
                )}
            </div>
        </div>
    )
}
