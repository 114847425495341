import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {Banner, ContentTileBanner, PublicationTileBanner, CarouselBanner, WideCarouselBanner, ThreeImageBanner, 
    TabbedList, SearchLayout, AccordionList, ImageBanner, SharepointContentTileBanner, 
    RichTextBanner, JSONList, ArrowLink, SharepointContentBanner} from '@vaccinessites/components';

import './ContentPage.scss';

export function ContentPage(props){    
    const [pageValue, setPageValue] = useState('');
    let pathname = useLocation().pathname;
    let history = useHistory();
    useEffect(()=>{
        props.CMS.getContent(pathname)
            .then(data=>{
                if(data){
                    setPageValue(data);
                    document.title = data.fields.pageTitle
                    TagManager.initialize({ gtmId: props.config.gtmId });
                }  else {
                    history.push('/404')
                }
            })
            return setPageValue('')
    }, [pathname])

    return (
        <>
            {
                pageValue && pageValue.fields.pageContentArea && <> 
                {pageValue.fields.pageContentArea.map((content, i)=>{
                    let contentType=content.sys.contentType.sys.id;
                    if(contentType=="banner"){
                        return (
                        <div className={"banner-container "+content.fields.template } key={'banner-'+i}>
                            <Banner banner = {content} /> 
                        </div>
                        )
                    } //load other content types in if else statements
                    else if (contentType == "contentTileBanner"){
                        return (
                            <div className="content-tile-banner-container" key={'content-tile-banner-'+i}>
                                <ContentTileBanner contentTileBanner = {content} /> 
                            </div>
                            )
                    } else if (contentType == "publicationTileBanner"){
                        return (
                            <div className="publication-tile-banner-container" key={'publication-tile-banner-'+i}>
                                <PublicationTileBanner publicationTileBanner = {content} config={props.config}/> 
                            </div>
                            )
                    } else if (contentType==="carouselBanner"){
                        return (
                            <div className="carousel-banner-container" key={'carousel-banner-'+i}>
                                <CarouselBanner carousel={content}/>
                            </div>
                        )
                    } else if (contentType==="wideCarouselBanner"){
                        return (
                            <div className="wide-carousel-banner-container" key={'wide-carousel-banner-'+i}>
                                <WideCarouselBanner widecarousel={content}/>
                            </div>
                        )
                    } else if (contentType==="threeImageBanner"){
                        return (
                            <div className="three-image-container" key={'three-image-'+i}>
                                <ThreeImageBanner banner={content}/>
                            </div>
                        )
                    } else if (contentType==="tabbedList"){
                        return (
                            <div className="tabbed-list-container" key={'tabbed-list-'+i}>
                                <TabbedList tabbedList={content} CMS={props.CMS} config={props.config}/>
                            </div>
                        )
                    } else if (contentType==="searchLayout"){
                        return (
                            <div className="search-layout-container" key={'search-container-'+i}>
                                <SearchLayout search={content} CMS={props.CMS} config={props.config}/>
                            </div>
                        )
                    } else if (contentType==="accordionList"){
                        return (
                            <div className="accordion-list-container" key={'accordion-container-'+i}>
                                <AccordionList accordionList={content} CMS={props.CMS}/>
                            </div>
                        )
                    } else if (contentType==="richTextBanner") {
                        return (
                            <div className="rich-text-banner-container" key={'rich-text-banner-'+i}>
                                <RichTextBanner richTextBanner={content} CMS={props.CMS}/>
                            </div>
                        )
                    } else if (contentType==="imageBanner"){
                        return (
                            <div className="image-banner-container" key={'image-banner-container-'+i}>
                                <ImageBanner imageBanner={content} CMS={props.CMS}/>
                            </div>
                        )
                    } else if (contentType==="sharepointContentTileBanner"){
                        return (
                            <div className="sharepoint-banner-container" key={'sharepoint-banner-container-'+i}>
                                <SharepointContentTileBanner banner= {content} CMS={props.CMS} config={props.config}/>
                            </div>
                        )
                    }
                    else if (contentType==="jsonList"){
                        return (
                            <div className="json-container" key={'json-container-'+i}>
                                <JSONList JSON={content} CMS={props.CMS}/>
                            </div>
                        )
                    }
                    else if (contentType==="arrowLink"){
                        return (
                            <div className={"arrowLink-container arrow-" + content.fields.type} key={'arrowLink-container-'+i}>
                                <ArrowLink arrowLink={content} CMS={props.CMS}/>
                            </div>
                        )
                    }
                    else if (contentType==="sharepointContentBanner"){
                        return (
                            <div className={"sharepointContentBanner-container"} key={'sharepointContentBanner-container-'+i}>
                                <SharepointContentBanner sharepointList={content} CMS={props.CMS} config={props.config}/>
                            </div>
                        )
                    }
                    })}
                </>
            }           
        </> 
    );
}