import React, {useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';
import useDraggableTable from '../Hooks/useDraggableTable'
import {StudyResultsModal} from '../Modal/StudyResultsModal';
import {PublicationsModal} from "../Modal/PublicationsModal";
import './ResultsTable.scss';

export function ResultsTable (props) {
    const draggable = useDraggableTable()
    return (
        <>
        <Table ref={draggable} responsive borderless className={props.loading ? "results-table table-loading" : "results-table"}> 
            <thead>
                <tr>
                    {props.columns && props.columns.map((column,i)=>{
                        return <th key={i}>{column.label}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {props.items && props.items.map((row, i)=>{
                    let item = row.fields;
                    return (
                        <tr key={i}>
                            {props.columns.map(col=>{
                                const isLink = col.isLink;
                                const isDate = col.isDate;
                                const isStudyResultsModal = col.isStudyResultsModal;
                                const isPublicationsModal = col.isPublicationsModal;
                                if(isLink){
                                    if(item[col.id] && item[col.id].url) {
                                        return (
                                        <td key={i + col.id}>
                                            {item[col.id] && <a href={item[col.id].url.includes("bmgf.sharepoint.com/sites/CAVD/CSF%20Study%20Results")|| item[col.id].url.includes("bmgf.sharepoint.com/sites/CAVD-CSFServices") || item[col.id].url.includes("www.cavd.org/Pages/StudyResults") ? "/app/cavd-request-permission" : item[col.id].url} target="_blank" aria-label={'open link to '+item.title}><i className="bi bi-link" aria-hidden="true"></i></a>}
                                        </td>
                                    ) 
                                    } else {
                                        return (
                                            <td >
                                              
                                            </td>
                                        )
                                    }
                                } else if (isDate){
                                    return (<td key={i + col.id}>
                                        {item[col.id] && new Date(item[col.id]).toLocaleDateString('en-us')}
                                    </td>)
                                }  else if (isStudyResultsModal) {
                                    return(
                                    <td key={i + col.id}>
                                        <StudyResultsModal modal={item}></StudyResultsModal>
                                    </td>  
                                    )
                                } 
                                else if (isPublicationsModal) {
                                    return(
                                    <td key={i + col.id}>
                                        <PublicationsModal modal={item}></PublicationsModal>
                                    </td>  
                                    )
                                }
                                else {
                                    if((col.id == "authors") && item[col.id])
                                    {
                                        return (<td key={i + col.id}>
                                            {item[col.id].replace( /(<([^>]+)>)/ig, ' ').replace(/&#[0-9]+;/g, ' ')}
                                        </td>)
                                    }
                                    else
                                    {
                                    return (<td key={i + col.id}>
                                        {item[col.id]}
                                    </td>)
                                    }
                                }
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        {!props.items || props.items.length === 0 ? <p className="no-results">{props.noResultsText}</p>: null}
        </>
    )
}