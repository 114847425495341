import React, { useEffect, useRef, useState } from "react"

/**
  * Make an element scrollable by dragging
  * @param buttons - Buttons user can drag with. See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
  * @returns {React.MutableRefObject} -  The ref to be applied to to the element to make it draggable
  */
export default function useDraggableTable(buttons = [1, 4, 5]) {
  // Ref to be attached to the element we want to drag
  const ref = useRef()
  //cannot put ref directly on React-Bootstrap responive container
  const tableParent = ref && ref.current && ref.current.parentElement
  // Position of the mouse on the page on mousedown
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  // Amount the draggable element is already scrolled
  const [startScrollLeft, setStartScrollLeft] = useState(0)
  const [startScrollTop, setStartScrollTop] = useState(0)
  function checkOverflow(elem) {
    if (elem){
      const elemWidth = elem.getBoundingClientRect().width
      const parentWidth = elem.parentElement.getBoundingClientRect().width
      return elemWidth > parentWidth
    } 
    return;
  }
  useEffect(() => {
    if(checkOverflow(ref.current)){
      //only attach handlers if element overflows
    function handleDown(e) {
      // Only allow dragging inside of target element
      if (!tableParent.contains(e.target)) {
          return
        }
      // Set initial positions of mouse element scroll
      setStartX(e.pageX - tableParent.offsetLeft)
      setStartY(e.pageY - tableParent.offsetTop)
      setStartScrollLeft(tableParent.scrollLeft)
      setStartScrollTop(tableParent.scrollTop)
      tableParent.style.cursor = 'grabbing';
      tableParent.style.userSelect = 'none';
    }
    function handleMove(e) {
        // Don't fire if other buttons are pressed
        if (!buttons.includes(e.buttons) || !tableParent.contains(e.target)) {
            return
        }
      e.preventDefault()
      // Position of mouse on the page
      const mouseX = e.pageX - tableParent.offsetLeft
      const mouseY = e.pageY - tableParent.offsetTop
      // Distance of the mouse from the origin of the last mousedown event
      const walkX = mouseX - startX
      const walkY = mouseY - startY
      // Set element scroll
      tableParent.scrollLeft = startScrollLeft - walkX
      tableParent.scrollTop = startScrollTop - walkY
    }
    function handleUp(e){
      tableParent.style.cursor = 'grab';
      tableParent.style.removeProperty('user-select');
    }
    // Add and clean up listeners
    document.addEventListener("mousedown", handleDown)
    document.addEventListener("mousemove", handleMove)
    document.addEventListener("mouseup", handleUp)
    return () => {
      document.removeEventListener("mousedown", handleDown)
      document.removeEventListener("mousemove", handleMove)
      document.removeEventListener("mouseup", handleUp)
    }
  }
  })

  return ref
}