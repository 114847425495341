import React, {useState, useEffect} from 'react';
import './Search.scss'

export function Search (props) {
    function onKeyDown(e){
        if (e.keyCode === 13) {
            props.onChange(e.target.value)
        }
    }
    return (
        <span className="search-container">
            <input onKeyDown={onKeyDown} className="search" name="search" placeholder={props.placeholderText} />
        </span>
    )
}