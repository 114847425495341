import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import './Layout.scss'

export function Layout(props){
    let location = useLocation();
    let [nav, setNav] = useState('');
    let [footer, setFooter] = useState('');
    let header=useRef(null);
    useEffect(()=>{
        props.CMS.getHeader()
            .then(data=>{
                if(data){
                    setNav(data);
                }
            })
        props.CMS.getFooter()
            .then(footerData=>{
                if(footerData){
                    setFooter(footerData)
                }
            })
    }, [])
    return (
        <>
            <header ref={header}>
                {nav && <Navigation location={location} links={nav.links} brand={{img: nav.brandImg, alt: nav.brandAlt}} navOptions={props.navOptions}/>}
            </header>
            <main role="main" id="mainContent" className={"mainContent " + props.site} style={{paddingTop: header.current && header.current.clientHeight !== 0 && header.current.clientHeight}}>
                {props.children}
            </main>                
            <footer>{footer && <Footer footer={footer}/>}</footer>
        </>
    );
}
