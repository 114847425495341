import React from 'react';
import {CTA} from '../CTA/CTA';
import './Footer.scss';

export function Footer(props){
    return(
        <div className="container">
            <div className="footer-brand">
                <p>{props.footer.logoLabel}</p>
                <img src={props.footer.logo} 
                alt={props.footer.logoAlt} />
            </div>
            <div className="footer-bottom">
                <p>{props.footer.copyrightText}</p>
                <div className="footer-links">
                    {props.footer.footerLinks.map((link, i)=>{
                        return (
                            <React.Fragment key={link.path}>
                                <CTA url={link.path}>{link.title}</CTA>
                                {i !== props.footer.footerLinks.length-1 ? <span className="spacer" aria-hidden="true">|</span> : ""}
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}