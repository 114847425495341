import React, {useState, useEffect} from 'react';
import './ThreeImageBanner.scss';
import {CTA} from '../CTA/CTA';
import { CMSService } from "@vaccinessites/services";

export function ThreeImageBanner (props) {
    let ThreeImageBanner = props.banner.fields;
    let link = ThreeImageBanner.cta ? ThreeImageBanner.cta.fields : null;
    let backgroundColor = ThreeImageBanner.backgroundColor ? ThreeImageBanner.backgroundColor.toLowerCase().replace(' ', '') : null;

    return (
            <div className = {"three-image " + backgroundColor} >
                <div className="container">
                    <div className="text-container">
                        <h1 className="heading"> {ThreeImageBanner.heading} </h1>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(ThreeImageBanner.paragraph) }}></div>
                        {link && <CTA url={link.url} className={"button " + link.type} >{link.label}</CTA>}
                    </div>                
                    <div className="image-container" >
                        <div className="image-grid">
                            {
                                ThreeImageBanner.images && <> 
                                    {ThreeImageBanner.images.map((image, i)=>{   
                                        return (
                                            <img className={"img-fluid image-" + i }  src={image.fields.file.url} alt={image.fields.title} key={image.sys.id} />                         
                                        )
                                    })}          
                                </> 
                            } 
                        </div> 
                    </div> 
            </div>
        </div> 
    )
}