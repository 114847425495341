import React, {useState, useEffect} from 'react';
import { CMSService } from "@vaccinessites/services";
import {CTA} from '../CTA/CTA';
import './WideContentTile.scss';

export function WideContentTile (props) {
    let contentTile = props.contentTile.fields;
    let subheading = contentTile.subheading;
    let heading = contentTile.heading;
    let template = contentTile.template ? contentTile.template : "over-image";
    let image = contentTile.image ? contentTile.image : null;
    let link = contentTile.link ? contentTile.link : null;

    return (
        <div className= {"wide-content-tile " + template}>
            {(props.template === 'wide-image') && 
                <>
                {link ? 
                    <CTA url={contentTile.link.fields.url}>
                        <InnerTile image={image && image.fields}
                            heading={heading} subheading={subheading} text={contentTile.text} footer={contentTile.footer}/>
                    </CTA> :
                    <>
                        <InnerTile image={image && image.fields}
                            heading={heading} subheading={subheading} text={contentTile.text} footer={contentTile.footer}/>
                    </>
                }
                </>
            }
        </div>
    );
}

function InnerTile (props){
    let image = props.image;
    return (
    <>
        {image && <div className="image-container">
            <img className="img" src={image.file.url} alt= {image.title}/>
            <img className="img-overlay" src={props.imgOverlaySrc} alt={props.imgOverlayAlt}/>
        </div> }
        <div className="tile-text-container">            
            <h5 className="tile-heading">{props.heading}</h5>
            {props.subheading && <span className="tile-subheading"> {props.subheading} </span>}
            <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.text) }}></div>
            <div className = "footer"> {props.footer}</div>
        </div>
    </>
    )
}