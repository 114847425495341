import React, {useState, useEffect} from 'react';
import './Pagination.scss';

export function Pagination (props) {
    const currentPage = props.currentPage;
    const lastDisabled = currentPage <=1 || props.isLoading;
    const nextDisabled = !props.hasMoreResults || props.isLoading;
    function onLastClick(){
        props.onPrevPage()
    }
    function onNextClick(){
        props.onNextPage()
    }

    function calculateBeginningNumber(){
        return ((currentPage - 1) * props.resultsPerPage) + 1
    }

    function calculateEndNumber(){
        const total = calculateBeginningNumber() + props.numberOfResults - 1;
       return (nextDisabled ? total : currentPage * props.resultsPerPage)
    }
    return (
        <div className="pagination"> 
        {props.numberOfResults > 0 && !props.isLoading && 
            <>
            <button aria-label="Last Page" onClick={onLastClick}
                disabled={lastDisabled}> 
                <i className="bi bi-chevron-left" aria-hidden="true"></i> 
            </button>
            <span>{calculateBeginningNumber() + ' - ' + calculateEndNumber()}</span>
            <button aria-label="Next Page" onClick={onNextClick}
                disabled={nextDisabled}> 
                <i className="bi bi-chevron-right" aria-hidden="true"></i> 
            </button>
            </>}
        </div>
    )
}