import React, {useState, useRef } from 'react';
import { Navbar, Nav, Dropdown} from 'react-bootstrap';
import { NavLink, Link} from 'react-router-dom';
import { isInternalLink } from "@vaccinessites/utils";
import { useWindowSize } from '../Hooks/useWindowSize';
import './Navigation.scss';

const hamburgerBreakpoint = 991;

export function Navigation(props){
    const [isExpanded, setIsExpanded] = useState(false);
    let windowSize = useWindowSize();

    function toggleNavbar(){
        if(windowSize.width<=hamburgerBreakpoint){
            if(isExpanded){
                setIsExpanded(false)
            } else {
                setIsExpanded(true)
            }
        }
    }
    return (
    <Navbar expanded={isExpanded} expand="lg" className={props.navOptions.logoPlacement === "center" ? "centerBrand container" : "container"}>
        <a className="skip-link sr-only sr-only-focusable" href="#mainContent">Skip to main content</a>
        {props.navOptions.logoPlacement === "center" && windowSize.width >= hamburgerBreakpoint ? "" : 
        <Navbar.Brand href="/">
            <img src={props.brand.img} className="" alt={props.brand.alt}/>
        </Navbar.Brand>
        }
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleNavbar}><i className="bi bi-list"></i></Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
        {props.navOptions.logoPlacement === "center" && windowSize.width >= hamburgerBreakpoint ?  
            <Navbar.Brand href="/" style={{order: Math.ceil(props.links.length /2)+2 }}>
                <img src={props.brand.img} className="" alt={props.brand.alt}/>
            </Navbar.Brand> : ""
        }
        {props.links.map((link,i)=>{
            let isInternal = isInternalLink(link.path)
            if(link.children){
                return <LinkWithDropdown key={link.path} 
                    link={link} location={location} 
                    isInternal={isInternal} windowSize={windowSize}
                    isLast={i===props.links.length-1} i={i} toggleNavbar={toggleNavbar}/>
            } else if (isInternal){
                return (
                <Nav.Link as={NavLink} to={link.path} key={link.path} exact={true} onClick={toggleNavbar}>
                    <span>{link.title}</span>
                </Nav.Link>)
            } else {
                return <NavBar.Text>
                    <a href={link.path.includes('http') ? link.path : 'https://'+link.path}
                        target="_blank"
                        key={link.path} className="nav-link">
                    <span>{link.title}</span></a>
                </NavBar.Text>
            }
        })} 
        <a href={props.navOptions.portalLogin} className="btn-default" target="_blank">
                Log in
            </a>

        </Navbar.Collapse>
    </Navbar>
    )
}

function LinkWithDropdown (props){
    let [isOpen, setIsOpen] = useState(false);
    const dropdownRef=useRef(null);
    let link = props.link;
    let numsPerGroup = 7;  //items clustered per column  
    let groupedLinks = new Array(3).fill('').map((_, i) => link.children.slice(i * numsPerGroup, (i + 1) * numsPerGroup));


    function openDropdown(){        
        setIsOpen(true)
    }

    function closeDropdown(e){
        setIsOpen(false)
    }

    function toggleDropdown(e){
        if(!isOpen){
            openDropdown(e);
        } else {
            closeDropdown(e);
        }
    }
    function onMouseLeave(e){
        if(props.windowSize.width >= hamburgerBreakpoint){
            closeDropdown();
        }
      
    }
    function childCloseDropdown(e){
        if(e.relatedTarget===null){
            setIsOpen(false)
            return;
        }
        if(dropdownRef.current !== e.relatedTarget.closest('.dropdown')){
            setIsOpen(false)
        }
    }
        
    function isChildActive(path) {
        if ((props.location.pathname + props.location.hash) === path) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
    <Dropdown id={"navdropdown-"+props.i} show={isOpen} onMouseLeave={onMouseLeave} ref={dropdownRef}>
        <Dropdown.Toggle as={CustomToggle} id={"dropdown-custom-" + props.i} link={link} isInternal={props.isInternal}
            openDropdown={openDropdown} toggleDropdown={toggleDropdown} toggleNavbar={props.toggleNavbar}
            isOpen={isOpen} windowSize={props.windowSize}>
            <span>{link.title}</span>   
        </Dropdown.Toggle>
            <Dropdown.Menu flip className={props.isLast?"last-dropdown-menu":""}>
                <div className={groupedLinks[1].length > 0? "dropdown-multicol" : ""}>
                    {groupedLinks.map((links, l)=>{
                        return(
                            <div key={l+'-group'} className={groupedLinks[1].length > 0? "dropdown-col" : ""}>
                            {links.map((childLink,i)=>{
                                const isChildInternal = isInternalLink(childLink.path);
                                if(isChildInternal){

                                    if(childLink.children){

                                        let [IsSubOpen, setIsSubOpen] = useState(false);
                                        const subdropdownRef=useRef(null);
                                        function openSubDropdown(){        
                                            setIsSubOpen(true)
                                        }
                                    
                                        function closeSubDropdown(e){
                                            setIsSubOpen(false);
                                        }

                                        function toggleSubDropdown(e){
                                             
                                            if(!IsSubOpen){
                                                openSubDropdown(e);
                                               
                                            } else {
                                                closeSubDropdown(e);
                                            }
                                        }

                                        function onMouseLeaveSub(e){
                                            if(props.windowSize.width >= hamburgerBreakpoint){
                                                closeSubDropdown();
                                            }
                                           
                                        }
                                        function collapseNav(e){
                                            if(props.windowSize.width <= hamburgerBreakpoint && e.target.tagName.toLowerCase() === 'span'){
                                                
                                                closeSubDropdown();
                                                closeDropdown();
                                                props.toggleNavbar();

                                            }
                                        }

                                        return (
                                      
                                        <Dropdown id={"navdropdown-sub" + props.i} show={IsSubOpen} onMouseLeave={onMouseLeaveSub} className="dropright"
                                         key={childLink.path} ref={subdropdownRef} onClick={collapseNav} onSelect={props.toggleNavbar}> 
                                        <Dropdown.Toggle as={CustomToggle} id={"dropdown-custom-sub" + i} link={childLink} isInternal={props.isInternal} 
                                        openDropdown={openSubDropdown} isOpen={IsSubOpen} toggleDropdown={toggleSubDropdown} windowSize={props.windowSize} >
                                                <span>{childLink.title}</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div>
                                            {childLink.children.map((sublink,s)=>{
                                                return(
                                                <Dropdown.Item as={Link} key={s+'-'+sublink.path} eventKey={s} 
                                                to={sublink.path} onClick={closeSubDropdown}
                                                className="nav-link" active={isChildActive(sublink.path)} 
                                                onSelect={props.toggleNavbar}>
                                                    <span>{sublink.title}</span>
                                                </Dropdown.Item>)
                                            })}
                                            </div>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        
                                        )
                                    }
                                    else{
                                    return (
                                    <Dropdown.Item as={NavLink} key={i+'-'+childLink.path} eventKey={i} 
                                        to={childLink.path} onBlur={childCloseDropdown} onClick={closeDropdown}
                                        active={isChildActive(childLink.path)} className="nav-link" onSelect={props.toggleNavbar}>
                                            <span>{childLink.title}</span>
                                    </Dropdown.Item>
                                    
                                )}}
                                else {
                                    return (
                                    <Dropdown.Item key={i+'-'+childLink.path} eventKey={i} 
                                        href={childLink.path} onBlur={childCloseDropdown} onClick={closeDropdown}
                                        active={isChildActive(childLink.path)} className="nav-link" target="_blank">
                                        <span>{childLink.title}</span>
                                    </Dropdown.Item>
                                    )
                                }
                            })}
                            </div>
                        )
                    })}
                </div>
            </Dropdown.Menu>
    </Dropdown>
    )
}

const CustomToggle = React.forwardRef(({ children, openDropdown, toggleDropdown, onBlur, link, isOpen, windowSize, isInternal, toggleNavbar }, ref) => {
    function onMouseEnter(){
        if(windowSize.width >= hamburgerBreakpoint){
            openDropdown();
        }
    }
    return (
        <>
        {isInternal ? 
            <Nav.Link as={NavLink} to={link.path} key={link.path} onMouseEnter={onMouseEnter} 
            onBlur={onBlur} ref={ref} onClick={toggleNavbar}>
                {children}
            </Nav.Link> :
            <Navbar.Text>
                <a href={link.path.includes('http') ? link.path : 'https://'+link.path} 
                    target="_blank"
                    key={link.path} className="nav-link" onMouseEnter={onMouseEnter} 
                    onFocus={onMouseEnter} onBlur={onBlur} ref={ref}>
                    {children}
                </a>
            </Navbar.Text>
            }
             
        {windowSize.width <= hamburgerBreakpoint ? (isOpen ?
            <button className="dropdown-button" onClick={toggleDropdown} aria-label={"close "+link.title+" dropdown"}><i className="bi bi-x"></i></button> :
            <button className="dropdown-button" onClick={toggleDropdown} aria-label={"open "+link.title+" dropdown"}><i className="bi bi-plus"></i></button>): ""}
    </> )
});
