import React, {useState, useEffect, useRef} from 'react';
import { Banner } from '../Banner/Banner';
import { Search } from './Search';
import { Sort } from './Sort';
import { ResultsTable } from './ResultsTable';
import { Pagination } from './Pagination';
import { SharepointService } from '@vaccinessites/services';
import './SearchLayout.scss';

export function SearchLayout (props) {
    const [searchValue, setSearchValue] = useState('');
    const [sortValues, setSortValues] = useState([]);
    const [skipToken, setSkipToken] = useState("");
    const [currentPage, setCurrentPage] = useState(1)
    const [prevSkipTokens,setPrevSkipTokens] = useState([""])
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(true);
    const searchLayout = props.search.fields;
    const Sharepoint = new SharepointService(props.config.baseAPIUrl, searchLayout.dataSource);
    const sortsConfig = Sharepoint.getSortValues();
    const tableColumns = Sharepoint.getTableColumns();
    const resultsDiv = useRef(null)

    function searchOnChange(query){
        setSearchValue(query)
    }
    function sortOnChange(value, field){
        setSortValues(prevSorts=>{
            let sorts = [...prevSorts];
            if(sorts.length === 0){
                sorts.push({
                    "columnname": field,
                    "order": value
                })
            } else {
                if(value === sorts[0].order && field === sorts[0].columnname){
                    sorts = []
                } else {
                    sorts = []
                    sorts.push({
                        "columnname": field,
                        "order": value
                    })
                }
            }
            return sorts
        })
    }

    function nextPage(){
        setCurrentPage(currentPage + 1)
        setPrevSkipTokens(prevTokens=>{
            let tokens = [...prevTokens]
            tokens.push(results.skiptoken)
            return tokens
        })
        setSkipToken(results.skiptoken)
    }

    function prevPage(){
        const prevPageToken=prevSkipTokens[prevSkipTokens.length - 2]
        setSkipToken(prevPageToken)
        setCurrentPage(currentPage - 1);
        setPrevSkipTokens(prevTokens=>{
            let tokens = [...prevTokens]
            tokens.pop()
            return tokens
        })
    }

    function newSearch(resetPagination){
        setLoading(true)
        let options = {
            sort: sortValues,
            search: searchValue,
            skipToken: resetPagination ? "" : skipToken,
            numberOfResults: searchLayout.numberOfResultsPerPage,
            from: (currentPage - 1) * searchLayout.numberOfResultsPerPage
        }
        Sharepoint.getDataFromDataSource(options).then(data=>{             
            setResults(data)                                     
            setLoading(false);
            window.scrollTo({
                top: resultsDiv.current.offsetTop - 100,
                behavior: 'smooth'
            })
        }).catch(err=>{
            console.log(err)
            setResults({data: []})
            setLoading(false)
        })
    }

    useEffect(()=>{
        //trigger new search when search changes
        setPrevSkipTokens([""]);
        setCurrentPage(1)
        if(searchValue.length == 0 || currentPage == 1)
            newSearch(true)
    }, [searchValue])

    useEffect(()=>{
        //trigger new search when sort changes
        setPrevSkipTokens([""]);
        setCurrentPage(1)
        if(searchValue.length == 0 || currentPage == 1){
            var delayInMilliseconds = 1000;
            setTimeout(function() {
                newSearch(true)
            }, delayInMilliseconds);
        }

    }, [sortValues])

    useEffect(()=>{
        //trigger new search when page changes
        newSearch(false)
    }, [currentPage])
    return (
        <div className={"SearchLayout " + searchLayout.dataSource.replace(/\ /g, '')}>
            <Banner banner={{fields: { 
                    backgroundColor:"white",
                    heading: searchLayout.heading,
                    template: 'none-one-column',
                    paragraph: searchLayout.paragraph,
                    textAlignment: searchLayout.bannerTextAlignment
                }}}>
                <div className="sub-paragraph container" dangerouslySetInnerHTML={{ __html: props.CMS.getRichText(searchLayout.subparagraph) }}></div>
            </Banner>
            <div className="filters container">
                <Sort options={sortsConfig} onClick={sortOnChange} selectedSort={sortValues} dropdownLabel={searchLayout.sortDropdownLabel || "Sort By..."}/>
                <Search onChange={searchOnChange} placeholderText={searchLayout.searchPlaceholderText || "Search"}/>
            </div>
            <div ref={resultsDiv} className={"results container "+ (loading ? "container-loading" : "")}>
                {!results && loading && <p className="loading">{searchLayout.loadingText || "Loading..."}</p>}
                {results && 
                <>
                    <ResultsTable items = {results.data} columns={tableColumns} loading={loading} noResultsText={searchLayout.noResultsText || "No results available."}/>
                    {loading && <p className="loading">{searchLayout.loadingText || "Loading..."}</p>}
                    <Pagination onNextPage={nextPage} onPrevPage={prevPage} currentPage={currentPage} isLoading={loading}
                        resultsPerPage={searchLayout.numberOfResultsPerPage} numberOfResults={results.data.length} hasMoreResults={(results.skiptoken && results.skiptoken !== "") || (results.total > currentPage * searchLayout.numberOfResultsPerPage) }/>
                </>}
            </div>
        </div>
    )
}
