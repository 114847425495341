import React, {useState, useEffect} from 'react';
import {Dropdown, Form} from 'react-bootstrap';
import './Sort.scss';

export function Sort (props) {
    let selectedSortVal = props.selectedSort[0];
    
    return (
        <div className="sort">
            <Dropdown>
                <Dropdown.Toggle id="sort-dropdown">{props.dropdownLabel}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.options && props.options.map((option)=>{
                        return (
                        <React.Fragment key={option.id}>
                        <Dropdown.ItemText>{option.label}</Dropdown.ItemText>
                        {option.values.map(value=>{
                            let isChecked = selectedSortVal && selectedSortVal.columnname === option.id && selectedSortVal.order === value.value;
                            return(
                                <CustomDropdownItem as={Dropdown.Item} key={value.id} id={value.id} 
                                    label={value.label} value={value.value} name={option.id}
                                    onClick={props.onClick} isChecked ={isChecked}/>
                            )
                        })}
                        </React.Fragment>)
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

const CustomDropdownItem = React.forwardRef(({children, onClick, label, id, value, name, isChecked}, ref) =>{    
    function radioOnChange(e){       
        onClick(value, name)
    }
    return (<Form.Check 
        type='radio'
        ref={ref}
        id={id}
        label={label}
        name={'SortOption'}
        value={value}
        checked={isChecked}
        onClick = {radioOnChange} 
      />)
})