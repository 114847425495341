import React, {useState, useEffect, Component} from 'react';
import { CMSService } from "@vaccinessites/services";
import { SharepointService } from '@vaccinessites/services';
import './SharepointContentBanner.scss';
import { JobandOpportunityData } from './JobandOpportunityData';

export function SharepointContentBanner (props) {
    let spBanner=props.sharepointList.fields;
    const [result, setResults] = useState();
    const Sharepoint = new SharepointService(props.config.baseAPIUrl, spBanner.dataSource);
    let footerContent = spBanner.footerContent;
    let backGroundColor = spBanner.backGroundColor
    useEffect(()=>{
    Sharepoint.getDataFromDataSource().then(data=>{             
        setResults(data.data) 
    }).catch(err=>{
        console.log(err)
        setResults({data: []})
      
    })
    }, [])
    return (
        <div className = {"sharepoint-content " + backGroundColor}>
            {result && 
                    result.sort((a, b) => a.fields.createdDate < b.fields.createdDate ? 1 : -1).map((item, i)=>{ 
                        return <JobandOpportunityData item={item.fields} key={i}/>
                    })}

                <div className={"footer-content"}>
                    <p dangerouslySetInnerHTML={{ __html: CMSService.getRichText(footerContent) }}></p>
                </div>
          
        </div> 
    )
}

