import React, {useState, useEffect, Component} from 'react';
import { CMSService } from "@vaccinessites/services";
import {CTA} from '../CTA/CTA';
import './RichTextBanner.scss';

export function RichTextBanner (props) {
    let richtextbanner = props.richTextBanner.fields;
    let richtext = richtextbanner.richText;
    let backgroundColor = richtextbanner.backgroundColor ? richtextbanner.backgroundColor.toLowerCase().replace(' ', '') : null;
    let id = richtextbanner.richtextId ? richtextbanner.richtextId.toLowerCase().replace(' ', '') : null;

    return (

        <div className = {"rich-text " + backgroundColor + " " + richtextbanner.advancedStyling} id={id}>
                <div className="text-container container"
                dangerouslySetInnerHTML={{ __html: CMSService.getRichText(richtext) }}>
                </div>
        </div> 
    )
}