import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Page404, ContentPage, GrantPage, Layout, ScrollToTop } from "@vaccinessites/components";
import { CMSService } from "@vaccinessites/services";
import { config } from "./config";
import './cavd.scss';

const defaultContent = require('./content/contentful-content.json');
const CMS= CMSService.initialize(defaultContent);

function App() {
  return (
    <div className="App">
      <Router>  
        <ScrollToTop/>
        <Layout CMS={CMS} site={'cavd'}
          navOptions={{
            portalLogin: config.portalLogin,
            loginHelp: config.loginHelp
            }}>
          <Switch>
            <Route exact path="/"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/about/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/about"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/research/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/research"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/grants/active-grants"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/grants/former-grants"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/grants/:slug"><GrantPage CMS={CMS} config={config}/></Route>
            <Route exact path="/grants"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/app/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/app"><ContentPage CMS={CMS} config={config}/></Route>
            <Route><Page404/></Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
