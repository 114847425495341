import React, {useState, useEffect} from 'react';
import { CMSService } from "@vaccinessites/services";
import {CTA} from '../CTA/CTA';
import './ContentTile.scss';

export function InnerTile (props) {
    let image = props.image;
    let imageBanner = props.imageBanner;
    let footerLink = props.url;
    
    var regContentVideo = new RegExp('/\w+\/[-+.\w]+/g');
    var CTType = image ? image.file.contentType : null;
    var CTTypeImageBanner = imageBanner ? imageBanner.file.contentType : null;
    var CType = CTType ? CTType.split("/") : "";
    var CTypeImageBanner = CTTypeImageBanner ? CTTypeImageBanner.split("/") : "";
    let fileUrl = props.config ? props.config.publicationTileFileImagURL : "";
    let contentType = props.contentType;
    let linkUrl = footerLink && footerLink.split('/')[1] == "publication" ?  imageBanner.file.url : footerLink;

    if(CType[0]=== "video" || CTypeImageBanner[0]=== "video"){
    return (
    <>
        <CTA url={footerLink}>
            {image && <div className="video-container">
                <video className="img-fluid" controls>
                <source src={image.file.url} ></source>
                </video>
            </div> }
            {imageBanner && <div className="video-container">
                <video className="publication-img-fluid" controls>
                    <source src={imageBanner.file.url} ></source>
                </video>
            </div> }
        </CTA>
        { props.heading &&
        <div className="tile-text-container">
            {props.subheading && <span className="tile-subheading"> {props.subheading} </span>}
            <h5 className="tile-heading">{props.heading}</h5>
            <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.text) }}></div>
            <CTA url={footerLink}> <div className = "footer"> {props.footer}</div></CTA>
        </div> }
        { props.title &&
        <div>
            <span className="shortdescription tile-shortdescription-container tile-shortdescription" style={{backgroundColor: props.tagColor, color: props.fontColor}}> {props.categorytag} </span>
            <div className="tile-text-container">
                <h5 className="tile-heading">{props.title}</h5>
                <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.shortDescription) }}></div>
                <CTA url={footerLink}><div className = "footer"> {props.footer}</div></CTA>
                <br/>
            </div>
        </div> }
    </>
    )
    
    }
    else if(CTypeImageBanner[1]=== "pdf" || CTypeImageBanner[1]=== "vnd.openxmlformats-officedocument.presentationml.presentation" || CTypeImageBanner[1]=== "vnd.openxmlformats-officedocument.spreadsheetml.sheet" || CTypeImageBanner[1]=== "doc" || CTypeImageBanner[1]=== "pptx"  || CTypeImageBanner[1]=== "ppt" || CTypeImageBanner[1]=== "vnd.openxmlformats-officedocument.wordprocessingml.document" || CTypeImageBanner[1]=== "xls" || CTypeImageBanner[1]=== "csv" || CTypeImageBanner[1]=== "plain" || CTypeImageBanner[1]=== "zip"){
        return (
            <>
                {imageBanner && <div className="image-container">
                {linkUrl ?
                    <a href={linkUrl} title={imageBanner.file.title} target="_blank">
                        <img className="publication-img-fluid" src={fileUrl} alt={props.imgOverlayAlt}/>
                    </a> : <a href={imageBanner.file.url} title={imageBanner.file.title} target="_blank">
                        <img className="publication-img-fluid" src={fileUrl} alt={props.imgOverlayAlt}/>
                    </a> }
                </div> }
                { props.title &&
                <div>
                    <span className="shortdescription tile-shortdescription-container tile-shortdescription" style={{backgroundColor: props.tagColor, color: props.fontColor}}> {props.categorytag} </span>
                    <div className="tile-text-container">
                        <h5 className="tile-heading">{props.title}</h5>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.shortDescription) }}></div>
                        {linkUrl ? <a href={linkUrl} target="_blank" className="footer" style={{ textDecoration: 'none'}}>{props.footer}</a> : <a href={imageBanner.file.url} target="_blank" className="footer" style={{ textDecoration: 'none'}}>{props.footer}</a>}
                        
                        <br/>
                    </div>
                </div> }
            </>
            )
    }
    else{
        return (
            <>
            {contentType == "publication" ? 
            <>
                <CTA url={footerLink}>
                    {imageBanner && <div className="image-container">
                        <img className="publication-img-fluid" src={imageBanner.file.url} alt= {imageBanner.title}/>
                        <img className="img-overlay" src={props.imgOverlaySrc} alt={props.imgOverlayAlt}/>
                    </div> }
                </CTA>
                { props.title &&
                <div>
                    <span className="shortdescription tile-shortdescription-container tile-shortdescription" style={{backgroundColor: props.tagColor, color: props.fontColor}}> {props.categorytag} </span>
                    <div className="tile-text-container">
                        <h5 className="tile-heading">{props.title}</h5>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.shortDescription) }}></div>
                        <CTA url={footerLink}><div className = "footer"> {props.footer}</div></CTA>
                        <br/>
                    </div>
                </div> } </> :
                <>
                <CTA url={footerLink}>
                    {image && <div className="image-container">
                        <img className="img-fluid" src={image.file.url} alt= {image.title}/>
                        <img className="img-overlay" src={props.imgOverlaySrc} alt={props.imgOverlayAlt}/>
                    </div> }
                    { props.heading &&
                    <div className="tile-text-container">
                        {props.subheading && <span className="tile-subheading" style={{backgroundColor: props.tagColor, color: props.fontColor}}> {props.subheading} </span>}
                        <h5 className="tile-heading">{props.heading}</h5>
                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: CMSService.getRichText(props.text) }}></div>
                        <div className = "footer"> {props.footer}</div>
                    </div> }
                </CTA>
                
                </>
                }
            </>

            )
    }
}