import React, { Component, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {CTA} from '../CTA/CTA';
import './Modal.scss';


export function StudyResultsModal (props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let link=props.modal.studyResultsLink && props.modal.studyResultsLink.url ? props.modal.studyResultsLink : null ;
    
    return (
        <>
          <Link onClick={handleShow}> 
            {props.modal.publicStudyTitle}
          </Link>
    
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title> CSF Studies - {props.modal.publicStudyTitle} </Modal.Title>
              <button type="button" class="bi-x-lg" aria-label="Close" onClick={handleClose}></button>
            </Modal.Header>
            <Modal.Body>
              <h5>Study Information</h5>
              {props.modal.publicStudyDescription && <p>{props.modal.publicStudyDescription.replace( /(<([^>]+)>)/ig, ' ').replace(/&#[0-9]+;/g, ' ')} </p>}
              <h5>CAVD Grantee</h5>
              <p>{props.modal.cavdAffiliation}</p>
              <h5>Central Service Facilities</h5>
              <p>{props.modal.recipientCSF}</p>
              <h5>Study Results</h5>
              {link && <p><a href={props.modal.studyResultsLink.url.includes("bmgf.sharepoint.com/sites/CAVD/CSF%20Study%20Results") || props.modal.studyResultsLink.url.includes("bmgf.sharepoint.com/sites/CAVD-CSFServices") || props.modal.studyResultsLink.url.includes("www.cavd.org/Pages/StudyResults") ? "/app/cavd-request-permission" : link.url} target="_blank">View Study Results</a></p>}
              <h5>Unique ID</h5>
              <p>{props.modal.uniqueID}</p>
              <h5>Selected Assays</h5>
              <div>
                <div className="bullet-list">
                {
                props.modal.selectedAssays &&
                  props.modal.selectedAssays.map((content=>{
                    return(
                      <ul>
                        <li>{content.lookupValue} {content.lookupValue == "Other Assays" ? <ul><li>{props.modal.assayOtherDetails}</li></ul> : null}
                        </li>
                      </ul>
                    )}))
                    }
                    {
                      props.modal.selectedAssaysRaw &&
                        props.modal.selectedAssaysRaw.map((content=>{
                          return(
                            <ul>
                              <li>{content} {content == "Other Assays" ? <ul><li>{props.modal.assayOtherDetails}</li></ul> : null}
                              </li>
                          </ul>
                          )}))
                          }
                </div>
              </div>
              {props.modal.selectedAssaysRaw == null && props.modal.selectedAssays == null ? <p >None Selected</p>: null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
}