import React, {useState, useEffect, Component} from 'react';
import {CTA} from '../CTA/CTA';
import { CMSService } from '@vaccinessites/services';
import './JobandOpportunityData.scss';


export function JobandOpportunityData (props) {
    let date = new Date(props.item.createdDate).toLocaleDateString('en-us',{month: "long",day: "numeric", year: "numeric"})
    let richtext = props.item.publicDescription;


  
    return (
        <div className='item-content'>
             <div className="container">
            <h4>
                <CTA url={props.item.jobLink.url} className={'cta-link'}>{props.item.jobTitle} </CTA>
            </h4>
            <p className="jobDesc"
                dangerouslySetInnerHTML={{ __html: CMSService.getRichText(richtext) }}>
            </p>   
            <p>By {props.item.jobOrganization} | {date}</p>
            <hr></hr>
            </div>
        </div>
        
    )
}