const prod = {
    stage: "prod",    
    portalLogin: "https://bmgf.sharepoint.com/sites/cavd",
    loginHelp: "/app/log-in-help",
    baseAPIUrl: "https://ghvacapp0006prd.azurewebsites.net/api/",
    gtmId: "GTM-M7KZ9ZB"
};

const uat = {
    stage: "uat",
    portalLogin: "https://bmgf.sharepoint.com/sites/cavd",
    loginHelp: "/app/log-in-help",
    baseAPIUrl: "https://ghvacapp0006stg.azurewebsites.net/api/",
    gtmId: "GTM-58PBHW4"
}

const dev = {
    stage: "dev",
    portalLogin: 'https://bmgf.sharepoint.com/sites/cavd',
    loginHelp: "/app/log-in-help",
    baseAPIUrl: "https://ghvacapp0006dev.azurewebsites.net/api/",
    gtmId: "GTM-58PBHW4"
};


let env = dev;
if (process.env.REACT_APP_STAGE === 'production') {
    env = prod;
} else if (process.env.REACT_APP_STAGE === 'uat') {
    env = uat;
}

env.website = 'cavd';
export const config = env;
